var review_token = 'H0Q5mOmjKQOxtx2SlOeQgeP4bzASqfkUCvkuWCxBtpkbTAByM9';

var review_target = 'review-container';

( function ( $ ) {

    "use strict";

    $( document ).ready( function () {

        $( 'body' ).append( '<script type="text/javascript" src="https://reviewsonmywebsite.com/js/embed.js"></script>' );

    } );

} )( jQuery );


